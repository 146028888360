// npm install vuex@3
// npm install vue-router@3
import Vue from 'vue'
import App from './App.vue'
import router from './router';

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

// aws 다시 쓰기 및 리디렉션 설정값
// [
//   {
//       "source": "https://sangsa.co.kr",
//       "target": "https://www.sangsa.co.kr",
//       "status": "302",
//       "condition": null
//   },
//   {
//       "source": "</^[^.]+$|\\.(?!(mp3|mp4|css|gif|ico|jpg|js|png|txt|svg|woff|ttf|map|json)$)([^.]+$)/>",
//       "target": "/",
//       "status": "200",
//       "condition": null
//   }
// ]