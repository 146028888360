import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const NotFound = () => import('../views/NotFound')
const NotSupported = () => import('../views/NotSupported')
const Home = () => import('../views/Home')
const Beta = () => import('../views/Beta')

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  saveScrollPosition: true,
  routes: [
    { path: '*', name: "NOT-FOUND", component: NotFound, meta: { title: 'Error 404 (Not Found)!!' } },
    { path: '/notsupported', name: "NOT-SUPPORTED", component: NotSupported, meta: { title: 'Not supported' } },
    { path: '/', name: "Home", component: Home, meta: { title: 'Robotic Process Automation' } },
    { path: '/index.html', name: "Index", component: Home, meta: { title: 'Robotic Process Automation' } },
    { path: '/beta', name: "Beta", component: Beta, meta: { title: 'Beta' } },
  ]
})

export default router
